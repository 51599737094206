import {
	mapGetters
} from "vuex"
import {
	cartList
} from "@/api/goods/cart"
export default {
	data: () => {
		return {
			cartData: [], // 购物车
			checkAll: false,
			totalPrice: "0.00",
			totalCount: 0,
			invalidGoods: [], // 失效商品集合
			loading: true,
			modifyNum: 1, // 防止数量跳动
		}
	},
	created() {
		this.getCartList()
	},
	computed: {
		...mapGetters(["defaultGoodsImage"])
	},
	methods: {
		// 获取购物车数据
		getCartList() {
			cartList({})
				.then(res => {
					if (res.code >= 0 && res.data.length) {
						this.handleCartList(res.data)
					}
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// 处理购物车数据结构
		handleCartList(data) {
			this.invalidGoods = []
			this.cartData = []
			var temp = {}
			data.forEach((item, index) => {				
				let cartGoodsItems = [];
				let siteInfo = {};
				item.goods_items.forEach((goodsItem, goodsIndex) => {
					if (goodsItem.goods_state == 1 && goodsItem.ng_goods_state == 1) {
						//库存不足
						if (goodsItem.num > goodsItem.stock) {
							this.invalidGoods.push(goodsItem);
							return;
						}
						// 如果最小限购超出库存则该商品失效
						if (goodsItem.min_buy > 0 && goodsItem.min_buy > goodsItem.stock) {
							this.invalidGoods.push(goodsItem);
							return;
						}
						
						cartGoodsItems.push(goodsItem);
						siteInfo = {
							siteId: goodsItem.site_id,
							siteName: goodsItem.site_name
						};
						
					} else {
						this.invalidGoods.push(goodsItem);
					}
				});

				if (cartGoodsItems.length > 0) {
					let cart = {
						stock_id: item.stock_id,
						stock_name: item.stock_name,
						goods_items: cartGoodsItems
					};
					if (temp['site_' + siteInfo.siteId] != undefined) {
						temp['site_' + siteInfo.siteId].cartList.push(cart);
					} else {
						temp['site_' + siteInfo.siteId] = {
							siteId: siteInfo.siteId,
							siteName: siteInfo.siteName,
							edit: false,
							//checked: true,
							cartList: [cart]
						};
					}
				}
			});

			/* this.invalidGoods.forEach(v => {
				if (v.sku_spec_format) {
					v.sku_spec_format = JSON.parse(v.sku_spec_format)
					console.log(v.sku_spec_format)
				} else {
					v.sku_spec_format = []
				}
			}) */
			
			Object.keys(temp).forEach(key => {
				this.cartData.push(temp[key]);
			});

			//首次加载选中第一个购物车商品
			if (this.cartData.length > 0 && this.loading) {
				this.cartData[0].cartList[0].checked = true;
				this.stockAllElection(0, 0);
			}

			this.calculationTotalPrice();
			/* this.cartList.forEach(v => {
				v.cartList.forEach(k => {
					if (k.sku_spec_format) {
						k.sku_spec_format = JSON.parse(k.sku_spec_format)
					} else {
						k.sku_spec_format = []
					}
				})
			}) */

		},
		// 单选
		singleElection(siteIndex, cartIndex, goodsIndex) {
			if (this.cartData[siteIndex].cartList[cartIndex].goods_items[goodsIndex].checked) {
				this.cartData.forEach((siteItem, _siteIndex) => {
					if (siteIndex != _siteIndex) {
						siteItem.checked = false;
					}
					
					siteItem.cartList.forEach((cartItem, _cartIndex) => {
						if (cartIndex != _cartIndex) {
							cartItem.checked = false;
						}
						
						let checkedAll = true;
						cartItem.goods_items.forEach((goodsItem, _goodsIndex) => {
							if (cartIndex != _cartIndex) {
								goodsItem.checked = false;
							}
							
							if (!goodsItem.checked) {
								checkedAll = false;
							}
						});
						
						if (cartIndex == _cartIndex && checkedAll) {
							cartItem.checked = true;
						}
					});
				});
			} else {
				this.cartData[siteIndex].cartList[cartIndex].checked = false;
			}
			this.calculationTotalPrice();
		},
		// 店铺全选
		siteAllElection(index) {
			this.cartData[index].cartData.forEach(item => {
				item.checked = this.cartData[index].checked
			})
			this.calculationTotalPrice()
		},
		// 仓库全选
		stockAllElection(siteIndex, cartIndex) {
			this.cartData[siteIndex].cartList.forEach((cartItem, _cartIndex) => {
				if (cartIndex != _cartIndex) {
					cartItem.checked = false;
				}
				
				cartItem.goods_items.forEach(goodsItem => {
					if (cartIndex == _cartIndex) {
						goodsItem.checked = this.cartData[siteIndex].cartList[cartIndex].checked;
					} else {
						goodsItem.checked = false;
					}
				});
			});
			this.calculationTotalPrice()
		},
		// 全选
		allElection() {
			if (this.cartData.length) {
				this.cartData.forEach(siteItem => {
					siteItem.checked = this.checkAll
					siteItem.cartData.forEach(item => {
						item.checked = this.checkAll
					})
				});
			}
			this.calculationTotalPrice()
		},
		// 计算购物车总价
		calculationTotalPrice() {
			if (this.cartData.length) {
				let totalPrice = 0,
					totalCount = 0,
					siteAllElectionCount = 0;

				this.cartData.forEach(siteItem => {
					let siteGoodsCount = 0;
					siteItem.cartList.forEach(item => {
						item.goods_items.forEach(goodsItem => {
							if (goodsItem.checked) {
								siteGoodsCount += 1;
								totalCount += goodsItem.num;
								totalPrice += goodsItem.discount_price * goodsItem.num;
							}
						});
					});
					if (siteItem.cartList.length == siteGoodsCount) {
						siteItem.checked = true;
						siteAllElectionCount += 1;
					} else {
						siteItem.checked = false;
					}
				});
				this.totalPrice = totalPrice.toFixed(2);
				this.totalCount = totalCount;
				this.checkAll = this.cartData.length == siteAllElectionCount;
			} else {
				this.totalPrice = "0.00";
				this.totalCount = 0;
			}
			this.modifyNum = 1;
		},
		// 删除单个
		deleteCart(siteIndex, cartIndex) {
			this.$confirm("确定要删除该商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: this.cartData[siteIndex].cartList[cartIndex].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.$message({
								type: "success",
								message: "删除成功"
							});
							this.loading = true;
							this.getCartList();
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			})
		},
		// 删除选择的购物车
		deleteCartSelected() {
			var cartIds = []
			var selectedItem = []
			this.cartData.forEach((siteItem, siteIndex) => {
				siteItem.cartList.forEach((item, cartIndex) => {
					if (item.checked) {
						cartIds.push(item.cart_id)
						selectedItem.push({
							siteIndex: siteIndex,
							cartIndex: cartIndex,
							siteId: siteItem.siteId,
							cartId: item.cart_id
						})
					}
				})
			})

			if (cartIds.length == 0) {
				this.$message({
					message: "请选择要删除的商品",
					type: "warning"
				})
				return
			}

			this.$confirm("确定要删除选择的商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: cartIds.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							selectedItem.forEach(selectedItem => {
								this.cartData.forEach((siteItem, siteIndex) => {
									siteItem.cartList.forEach((item, cartIndex) => {
										if (selectedItem.cartId == item.cart_id) {
											siteItem.cartList.splice(cartIndex, 1)
										}
										if (siteItem.cartList.length == 0) {
											this.cartData.splice(siteIndex, 1)
										}
									})
								})
							})
							this.calculationTotalPrice()
							this.$message({
								type: "success",
								message: "删除成功"
							})
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			})
		},
		// 清空失效商品
		clearInvalidGoods() {
			this.$confirm("确认要清空这些商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				var cartIds = []
				this.invalidGoods.forEach(goodsItem => {
					cartIds.push(goodsItem.cart_id)
				})
				if (cartIds.length) {
					this.$store
						.dispatch("cart/delete_cart", {
							cart_id: cartIds.toString()
						})
						.then(res => {
							if (res.code >= 0) {
								this.invalidGoods = []
								this.$message({
									type: "success",
									message: "删除成功"
								})
							} else {
								this.$message({
									message: res.message,
									type: "warning"
								})
							}
						})
						.catch(err => {
							this.$message.error(err.message)
						})
				}
			})
		},
		// 变更购物车数量
		cartNumChange(num, params) {
			//console.log(num)
			//console.log(params)
			if (num < 1 || !num) num = 1;
			// 防止数量跳动
			this.modifyNum = 0;
			this.$store
				.dispatch("cart/edit_cart_num", {
					num,
					cart_id: this.cartData[params.siteIndex].cartList[params.cartIndex].goods_items[params.goodsIndex].cart_id
				})
				.then(res => {
					if (res.code >= 0) {
						this.cartData[params.siteIndex].cartList[params.cartIndex].goods_items[params.goodsIndex].num = num;
						this.calculationTotalPrice();
					} else {
						this.$message({
							message: res.message,
							type: "warning"
						});
						this.modifyNum = 1;
					}
				})
				.catch(err => {
					this.$message.error(err.message);
					this.modifyNum = 1;
				})
		},
		// 结算
		settlement() {
			if (this.totalCount > 0) {
				let cart_ids = []
				this.cartData.forEach(siteItem => {
					siteItem.cartList.forEach(item => {
						item.goods_items.forEach(goodsItem => {
							if (goodsItem.checked) {
								cart_ids.push(goodsItem.cart_id)
							}
						});
					})
				})

				var data = {
					cart_ids: cart_ids.toString()
				}
				this.$store.dispatch("order/setOrderCreateData", data)
				this.$router.push({
					path: "/payment"
				})
			}
		},
		imageError(siteIndex, cartIndex, goodsIndex) {
			this.cartData[siteIndex].cartList[cartIndex].goods_items[goodsIndex].sku_image = this.defaultGoodsImage
		},
		imageErrorInvalid(index) {
			this.invalidGoods[index].sku_image = this.defaultGoodsImage
		}
	}
}
